import React from "react";
import Nav from "./Nav.js";

function Contact() {
  return (
    <div className="flex justify-center body">
      <div className="w-3/4">
        <Nav></Nav>
      </div>
    </div>
  );
}

export default Contact;
