import React from "react";
import { FaGithub, FaYoutube, FaDribbble } from "react-icons/fa";
import Nav from "./Nav.js";

function Home() {
  return (
    <div>
      <div className="flex justify-center body">
        <div className="w-3/4">
          <Nav></Nav>
          <div className="flex flex-col mt-40">
            <h1 className="text-mred text-5xl font-bold font-mwsans leading-none">
              Welcome,
            </h1>
            <h1 className="text-mblue-200 text-5xl font-bold font-mwsans leading-1">
              visitor
            </h1>
            <p className="font-lato text-xl mt-4">
              I’m Kyle Tu, a high school student, and a developer.
            </p>
            <p className="font-lato text-xl">
              Design is also something I'm into.
            </p>
            <div className="flex mt-5">
              <a href="https://github.com/efMINEus" className="mr-5">
                <FaGithub size="2rem" className="github transition"></FaGithub>
              </a>
              <a href="https://dribbble.com/kyletu" className="mr-5">
                <FaDribbble
                  size="2rem"
                  className="dribbble transition"
                ></FaDribbble>
              </a>
              <a href="https://www.youtube.com/">
                <FaYoutube
                  size="2rem"
                  className="youtube transition"
                ></FaYoutube>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="image">
        <img src="/images/svg/person.svg" alt="person"></img>
      </div>
    </div>
  );
}

export default Home;
