import React from "react";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <nav>
      <ul className="flex justify-start mt-10">
        <li>
          <h1 className="text-xl text-mblue-300 mr-10 font-lato hover:text-mblue-100">
            <Link to="/">
              <p>Home</p>
            </Link>
          </h1>
        </li>
        <li>
          <h1 className="text-xl text-mblue-300 mr-10 font-lato hover:text-mblue-100">
            <Link to="/projects">
              <p>Projects</p>
            </Link>
          </h1>
        </li>
        <li>
          <h1 className="text-xl text-mblue-300 font-lato hover:text-mblue-100">
            <Link to="/contact">
              <p>Contact</p>
            </Link>
          </h1>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
